<template>
  <base-layout>
    <div v-if="rentalDetail === null" class="h-screen vld-parent">
      <loading :active="rentalDetail === null" :is-full-page="false" />
    </div>

    <div v-if="rentalDetail && tripDetail !== 'init'">
      <DetailScreen
        :rental-detail="rentalDetail"
        :trip-detail="tripDetail"
        @download-invoice="onInvoiceModalReq(rentalDetail.id)"
        @rental-fee-refund="showRentalFeeRefundPopup(rentalDetail)"
        @extension-fee-refund="showExtensionFeeRefundPopup(rentalDetail)"
        @force-completed="fetchDetail"
        v-on:updated="fetchDetail"
      />
    </div>

    <!-- <template v-if="vehicleLogs">
      <TimelineForTripDetailsScreen v-model="vehicleLogs" />
    </template> -->

    <DynamicInvoiceModal
      :htmlString="invoiceHtmlString"
      :id="invoiceId"
      pdfName="rent"
      @send-email="sendInvoiceEmail"
    />
    <refund-popup
      @refunded="handleRefunding('succeeded')"
      @failed="handleRefunding('failed')"
    />
  </base-layout>
</template>
<script>
import BaseLayout from '../shared/BaseLayout.vue'
import { useEndpoints } from '@/composables'

import { TransactionConfig } from '@/config/TransactionConfig'
import { EventBus } from '@/utils/EventBus'
// import { resolveProp } from '@/utils'

export default {
  components: {
    BaseLayout,
    DynamicInvoiceModal: () =>
      import('@/components/modals/DynamicInvoiceModal.vue'),
    RefundPopup: () =>
      import('@/composites/transaction/revenue/index/RefundPopup.vue'),
    DetailScreen: () =>
      import('@/composites/vehicle-rental/details/DetailScreen'),
  },
  data() {
    return {
      rentalDetail: null,
      tripDetail: 'init',
      showModal: false,
      invoiceModalDetails: {},
      symbol: '$',
      vehicleLogs: null,
      userLogs: null,
      invoiceHtmlString: '',
      invoiceId: '',
    }
  },
  async mounted() {
    await this.fetchDetail(this.id)
    // await this.fetchVehicleLogs(this.id)

    //inbound fetch listener (emitted from TripGoogleMapView)
    window.addEventListener('fetchRentalDetail', async (id) => {
      await this.fetchDetail(id)
    })
    EventBus.$on('refresh-rent', async () => {
      await this.fetchDetail(this.id)
    })
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    getCurrencySymbol() {
      const orgInfo = this.$store.getters['auth/organizationInfo']
      return orgInfo ? orgInfo.default_currency.symbol : '$'
    },
  },
  methods: {
    async fetchDetail(id) {
      if (!id) id = this.id

      const self = this

      await this.$http
        .get(useEndpoints.vehicleRental.single(id))
        .then((res) => {
          self.rentalDetail = res.data

          if (res.data.trip) {
            return self.$http.get(useEndpoints.trip.single(res.data.trip.id))
          }

          return 'no-trip'
        })
        .then((res) => {
          if (typeof res === 'string' && res === 'no-trip') {
            this.tripDetail = null
          } else {
            self.tripDetail = res.data
            self.$store.dispatch('lastTrip/saveStatus', res.data.status)
          }
        })
        .catch((err) => {
          console.log('err = ', err)
        })
    },
    async onInvoiceModalReq(id) {
      this.invoiceId = id
      this.$notify({
        group: 'generic',
        type: 'default',
        title: `Processing`,
        text: 'The action is being executed...',
      })

      await this.$http
        .get(useEndpoints.vehicleRental.getEmailTemplate(id))
        .then((res) => {
          this.invoiceHtmlString = res.data
          this.$modal.show('dynamicInvoiceModal')
        })
        .catch((err) => {
          console.log('email-template-err', err)
          this.$notify({
            group: 'generic',
            type: 'error',
            title: `Server Error [${err.response.status}]`,
            text: 'Unable to process the request!',
          })
        })
    },
    async sendInvoiceEmail(id) {
      await this.$http
        .post(useEndpoints.vehicleRental.sendEmail(id))
        .then((res) => {
          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: 'Success',
              text: res.data,
            },
            3000
          )
        })
        .catch((err) => {
          console.log('send-email-err', err)
          this.$notify({
            group: 'generic',
            type: 'error',
            title: `Server Error [${err.response.status}]`,
            text: 'Unable to process the request!',
          })
        })
    },
    showRentalFeeRefundPopup(item) {
      const modifiedItem = {
        id: item.invoice.id,
        original_amount: item.net_cost,
        user: item.rider,
        payment_method: item.invoice.payment_method,
        original_currency: item.original_currency,
      }
      EventBus.$emit(TransactionConfig.events.refundPopup, modifiedItem)
      this.$modal.show(TransactionConfig.events.refundPopup)
    },
    showExtensionFeeRefundPopup(item) {
      const modifiedItem = {
        id: item.rent_time_extension_invoice.id,
        original_amount: item.net_rent_time_extension_cost,
        user: item.rider,
        payment_method: item.rent_time_extension_invoice.payment_method,
        original_currency: item.original_currency,
      }
      EventBus.$emit(TransactionConfig.events.refundPopup, modifiedItem)
      this.$modal.show(TransactionConfig.events.refundPopup)
    },
    async handleRefunding(status = 'failed') {
      if (status === 'succeeded') {
        this.fetchDetail(this.id)
        this.$notify(
          {
            group: 'generic',
            type: 'success',
            title: 'Refunded',
            text: 'Successfully refunded to the rider.',
          },
          3000
        )
        return
      }
    },
    async fetchUserLogs(id) {
      await this.$http
        .get(useEndpoints.api.userTimelineLog(id))
        .then((res) => {
          this.$log.debug('userLogs = ', res.data.data)
          this.userLogs = res.data.data
        })
        .catch((err) => {
          console.log('userErr = ', err.response.data)
        })
    },
    async fetchVehicleLogs(id) {
      await this.$http
        .get(useEndpoints.api.vehicleTimelineLog(id))
        .then((res) => {
          this.$log.debug('vehicleLogs = ', res.data.data)
          this.vehicleLogs = res.data.data
        })
        .catch((err) => {
          console.log('vehicleErr = ', err.response.data)
        })
    },
  },
}
</script>

<style lang="sass"></style>
